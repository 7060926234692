$(function () {
    // https://stackoverflow.com/questions/46147949/using-rel-noopener-in-window-open
    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) {
            newWindow.opener = null;
        }
    };

    $(".js-tweet-mission").on("click", function (event) {
        event.preventDefault();
        const missionId = 2;
        const linkUrl = $(this).attr("href");

        openInNewTab(linkUrl, "_blank", "noopener,noreferrer");

        $.ajax({
            url: "/member/mission",
            type: "post",
            dataType: "json",
            data: { id: missionId },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
        })
            .done(function () {
                // ページが更新されるよう適当なパラメータをつける
                location.href = '/member?mission=2#mission-complete';
            })
            .fail(function (jqXHR, textStatus, errorThrown) {
                alert(
                    "システムエラーが発生いたしました。しばらく時間をおいてから再度お試しください。"
                );
                console.debug("jqXHR          : " + jqXHR.status); // HTTPステータスが取得
                console.debug("textStatus     : " + textStatus); // タイムアウト、パースエラー
                console.debug("errorThrown    : " + errorThrown.message); // 例外情報
            });
    });
});
